import { FC } from 'react'
import { SectionTrustpilotBannerProps } from '@magal/models'
import { styled } from '@magal/styles'
import { Star, TrustpilotIcon } from '@magal/icons'
import { useTranslation } from 'react-i18next'
import { Container, Link, ProgressSlider, SectionWrap } from '@magal/components'

const Wrapper = styled('div', {
  maxWidth: '1080px',
  margin: '0 auto',
  backgroundColor: '$green',
  borderRadius: '$r2',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$36',
  padding: '$56 $20 $56 $20',
  color: '$beige',
  '@lg': {
    gridTemplateColumns: 'repeat(2, 1fr)',
    padding: '$32',
  },
})

const Header = styled('div', {
  display: 'grid',
  gridTemplateRows: 'min-content',
  gap: '$56',
})

const Title = styled('h2', {
  projectFont: 'caps01',
  textAlign: 'center',
  '@lg': {
    textAlign: 'left',
  },
})

const Description = styled('div', {
  alignSelf: 'end',
  justifySelf: 'center',
  '@lg': {
    justifySelf: 'start',
  },
})

const Stars = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: 'repeat(5, min-content)',
  gap: '$16',
})

const Caption = styled('div', {
  projectFont: 'body01',
  lin: {
    marginTop: ['$16', '$28'],
  },
})

const IconWrapper = styled('div', {
  marginLeft: '$8',
  marginRight: '$8',
  display: 'inline-block',
})

const Content = styled('div', {
  display: 'grid',
  '@lg': {
    paddingLeft: '$36',
    paddingRight: '$36',
  },
})

const ProgressSliderWrapper = styled('div', {
  display: 'grid',
  order: 3,
  marginTop: '$28',
  '@lg': {
    order: 2,
    marginTop: '$0',
  },
})

const Review = styled('div', {
  display: 'grid',
  gridTemplateRows: 'min-content min-content',
  textAlign: 'center',
  gridRow: 1,
  gridColumn: 1,
  transition: 'opacity 400ms',
  variants: {
    isActive: {
      true: {
        opacity: 1,
        zIndex: 9,
      },
      false: {
        opacity: 0,
        zIndex: 1,
      },
    },
  },
})

const ReviewAuthor = styled('p', {
  projectFont: 'amalfi01',
  marginTop: '$16',
})

const ReviewNote = styled('div', {
  display: 'grid',
  justifySelf: 'center',
  gridAutoFlow: 'column',
  gridTemplateColumns: 'repeat(5, min-content)',
  gap: '$4',
  marginTop: '$12',
  '> svg': {
    width: '17px',
  },
})

const ReviewInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

const ReviewTitle = styled('p', {
  projectFont: 'body01Medium',
  marginTop: '$36',
})

const ReviewDescription = styled('p', {
  projectFont: 'body01',
  marginTop: '$16',
})

const StyledLink = styled(Link, {
  margin: '0 auto',
  order: 1,
  '@lg': {
    order: 3,
    marginTop: '$28',
  },
})

export const SectionTrustpilotBanner: FC<SectionTrustpilotBannerProps> = ({
  title,
  link,
  reviews,
}) => {
  const { t } = useTranslation('sectionTrustpilotBanner')

  const slides = reviews?.map(({ author, title, description }, index) => {
    return (
      <Review key={index}>
        {author && <ReviewAuthor>{author}</ReviewAuthor>}
        <ReviewNote>
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
        </ReviewNote>
        <ReviewInfo>
          {title && <ReviewTitle>{title}</ReviewTitle>}
          {description && <ReviewDescription>{description}</ReviewDescription>}
        </ReviewInfo>
      </Review>
    )
  })

  return (
    <SectionWrap>
      <Container>
        <Wrapper>
          <Header>
            <Title>{title}</Title>
            <Description>
              <Stars>
                <Star />
                <Star />
                <Star />
                <Star />
                <Star />
              </Stars>
              <Caption>
                {t('excellentOn')}
                <IconWrapper>
                  <TrustpilotIcon />
                </IconWrapper>
                {t('trustpilot')}
              </Caption>
            </Description>
          </Header>
          <Content>
            {slides && (
              <ProgressSliderWrapper>
                <ProgressSlider slides={slides}></ProgressSlider>
              </ProgressSliderWrapper>
            )}
            <StyledLink {...link} appearance={'outlineWhite'}>
              {t('readAllTheReviews')}
            </StyledLink>
          </Content>
        </Wrapper>
      </Container>
    </SectionWrap>
  )
}
